
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"
import { CharData } from "@/data/character/characterData"

import {
  ExpandableSection,
  EXPANDER_SLOT,
} from "@/components/ExpandableSection.vue"
import {
  CombatConfig,
  IEmitCombatConfig,
  getUpdate as initialConfig,
} from "@/components/dice/combat/CombatConfig.vue"

import { ACTION_ALL } from "@/data/combat/typesCombat"
import { TSkillId } from "@/types"

@Component({
  components: {
    CombatConfig,
    ExpandableSection,
  },
})
export class CombatConfigSection extends Vue {
  @Prop({ default: null }) charData!: CharData
  @Prop({ default: "" }) actionId!: ACTION_ALL | ""
  @Prop({ default: "" }) skillId!: TSkillId | ""
  @Prop({ default: "" }) itemId!: string
  @Prop({ default: false }) isMonster!: boolean
  @Prop({ default: 0 }) bonus!: number

  SLOT = EXPANDER_SLOT
  get itemName() {
    return this.charData.gear.inventory.find((i) => i.id === this.itemId)?.name
  }
  get mOpponent() {
    return this.isMonster ? "monster" : ""
  }

  onConfigUpdate(config: IEmitCombatConfig) {
    const updatedConf: ICombatDiceConfig = {
      ...config,
      itemId: config.item?.id,
    }
    this.$emit("dice-config", updatedConf)
  }

  created() {
    const config = {
      monster: this.isMonster,
      actionId: this.actionId,
      skillId: this.skillId,
      item: undefined,
      itemId: this.itemId,
      bonus: 0,
      modifiers: [],
      diceConf: {},
    }
    const update = initialConfig(config, this.charData)
    this.onConfigUpdate(update)
  }
}

export interface ICombatDiceConfig extends IEmitCombatConfig {}

export default CombatConfigSection
