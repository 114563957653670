
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import SvgIcon from "@/components/SvgIcon.vue"

@Component({
  components: {
    SvgIcon,
  },
})
export class FLCheckbox extends Vue {
  @Prop({ default: false }) value!: boolean
  @Prop({ default: "" }) label!: string
  @Prop({ default: "" }) iconName!: string
  @Prop({ default: false }) required!: boolean
  @Prop({ default: () => {} }) enterCb!: Function
  @Prop({ default: "left" }) labelPosition!: "left" | "right"
  @Prop({ default: false }) column!: boolean

  id = `${this.label}-flcheckbox`

  inputEvent($event: any) {
    this.$emit("input", $event.target.checked)
  }
}
export default FLCheckbox
