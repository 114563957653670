
// TODO: Split this up into smaller components
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import {
  ExpandableSection,
  EXPANDER_SLOT,
} from "@/components/ExpandableSection.vue"
import DiceInput from "@/components/dice/DiceInput.vue"
import { DICE_TYPE, IDiceConfig } from "@/dice/diceTypes"

import { DiceConfSummary } from "src/components/diceRoller/DiceConfSummary.vue"

@Component({
  components: {
    DiceInput,
    ExpandableSection,
    DiceConfSummary,
  },
})
export class DiceConfigSection extends Vue {
  @Prop({ default: null }) diceConf!: IDiceConfig

  EXPANDER_SLOT = EXPANDER_SLOT
  DICE_TYPE = DICE_TYPE

  updateDice(color: DICE_TYPE, value: number) {
    this.$emit("update", { ...this.diceConf, [color]: value })
  }
}

export default DiceConfigSection
