
import { Component, Prop, Watch, Vue } from "vue-property-decorator"
import SvgIcon from "@/components/SvgIcon.vue"
import {
  ExpandableSection,
  EXPANDER_SLOT,
} from "@/components/ExpandableSection.vue"
import { DICE_TYPE, IDiceConfig } from "@/dice/diceTypes"

import { DiceImg } from "@/components/diceRoller/DiceImg.vue"

@Component({
  components: {
    ExpandableSection,
    DiceImg,
  },
})
export class DiceConfSummary extends Vue {
  @Prop({ default: null }) diceConf!: IDiceConfig

  get diceSummary(): { diceType: DICE_TYPE; nbrDice: number }[] {
    const mList = Object.entries(this.diceConf).map(([key, val]) => {
      return {
        diceType: key as DICE_TYPE,
        nbrDice: val as number,
      }
    })
    return mList.filter((i) => i.nbrDice > 0)
  }
}

export default DiceConfSummary
