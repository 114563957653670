
import Modal from "@/components/Modal.vue"

import { CharData } from "@/data/character/characterData"
import { Component, Prop, Vue } from "vue-property-decorator"
import { IDiceConfig } from "@/dice/diceTypes"
import { ACTION_ALL, ACTION_FAST } from "@/data/combat/typesCombat"
import { TSkillId } from "@/types"
import { DiceCombatBody } from "src/components/dice/combat/DiceCombatBody.vue"
import { DiceCombatFooter } from "src/components/dice/combat/DiceCombatFooter.vue"

function defaultDice(): IDiceConfig {
  return {
    white: null,
    red: null,
    black: null,
    green: null,
    blue: null,
    orange: null,
  }
}

@Component({
  components: {
    Modal,
    DiceCombatBody,
    DiceCombatFooter,
  },
})
export class DiceCombatModal extends Vue {
  @Prop({ default: "" }) title!: string
  @Prop({ default: () => defaultDice() }) dice!: IDiceConfig
  @Prop({ default: null }) charData!: CharData
  @Prop({ default: false }) disablePush!: boolean
  @Prop({ default: "" }) actionId!: ACTION_ALL | ""
  @Prop({ default: "" }) skillId!: TSkillId | ""
  @Prop({ default: "" }) itemId!: string
  @Prop({ default: false }) isMonster!: boolean

  canRoll = true
  canPush = !this.disablePush
  rollClickCount = 0
  pushClickCount = 0

  close() {
    this.$emit("close")
  }

  onWillpower(value: number) {
    this.charData.willpower = value
  }

  updateCanPush(canPush: boolean) {
    this.canPush = canPush
  }

  handleEffectsApplied() {
    this.canPush = false
    this.canRoll = false
  }

  onRollClick() {
    this.rollClickCount++
  }
  onPushClick() {
    this.pushClickCount++
  }
}
export default DiceCombatModal
