
import ExpandableSection, {
  EXPANDER_SLOT,
} from "@/components/ExpandableSection.vue"

import { CharData } from "@/data/character/characterData"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { IDiceConfig, IDiceResult } from "@/dice/diceTypes"
import { ACTION_ALL, ACTION_FAST } from "@/data/combat/typesCombat"
import { TSkillId } from "@/types"
import { DiceRollEffects } from "src/components/diceRoller/DiceRollEffects.vue"
import { canPush, pushDice, rollDice } from "src/dice/diceRoller"
import {
  CombatConfigSection,
  ICombatDiceConfig,
} from "src/components/dice/combat/sections/CombatConfigSection.vue"
import { ModifiersSection } from "src/components/dice/combat/sections/ModifiersSection.vue"
import { DiceConfigSection } from "src/components/dice/combat/sections/DiceConfigSection.vue"
import { DiceProbabilitySection } from "src/components/dice/combat/sections/DiceProbabilitySection.vue"
import { DiceRollResultSection } from "src/components/dice/combat/sections/DiceRollResultSection.vue"
import { IResultSummary } from "src/components/dice/diceTypes"

@Component({
  components: {
    DiceRollEffects,
    ExpandableSection,

    CombatConfigSection,
    ModifiersSection,
    DiceConfigSection,
    DiceProbabilitySection,
    DiceRollResultSection,
  },
})
export class DiceCombatBody extends Vue {
  @Prop({ default: () => defaultDice() }) dice!: IDiceConfig
  @Prop({ default: null }) charData!: CharData
  @Prop({ default: false }) disablePush!: boolean
  @Prop({ default: "" }) actionId!: ACTION_ALL | ""
  @Prop({ default: "" }) skillId!: TSkillId | ""
  @Prop({ default: "" }) itemId!: string
  @Prop({ default: false }) isMonster!: boolean

  @Prop({ required: true }) rollClickCount!: number
  @Prop({ required: true }) pushClickCount!: number

  @Watch("rollClickCount")
  handleRoll() {
    this.diceRollResult = [rollDice(this.conf.diceConf)]
    this.updateCanPush()
  }

  @Watch("pushClickCount")
  handlePush() {
    this.diceRollResult = [
      pushDice(this.diceRollResult[0]),
      ...this.diceRollResult,
    ]
    if (this.charData.kin === "dwarf" && this.diceRollResult.length > 2) {
      this.charData.willpower--
    }
    this.updateCanPush()
  }

  get willpower(): number {
    return this.charData.willpower
  }
  @Watch("willpower")
  handleWillpower() {
    this.updateCanPush()
  }

  arrowRoll: number = 6

  canPush = this.disablePush
  canRoll = true

  diceRollResult: IDiceResult[] = []
  get resultSummary(): IResultSummary {
    const skullEffects = this.diceRollResult.length > 1
    const whiteSkulls = skullEffects ? this.diceRollResult[0].failWhite : 0
    const blackSkulls = skullEffects ? this.diceRollResult[0].failBlack : 0
    const success = this.diceRollResult[0].successes
    return { whiteSkulls, blackSkulls, success }
  }

  conf: ICombatDiceConfig = {
    diceConf: this.dice,
    bonus: 0,
    actionId: this.actionId,
    itemId: this.itemId,
    monster: !!this.isMonster,
    skillId: this.skillId || undefined,
    item: undefined,
    modifiers: [],
  }
  mDiceKey = "0"

  EXPANDER_SLOT = EXPANDER_SLOT

  updateCanPush() {
    const reactiveRoll =
      this.actionId === ACTION_FAST.parry || this.actionId === ACTION_FAST.dodge
    const _canPush = canPush(this.charData, this.diceRollResult, reactiveRoll)
    this.canPush = _canPush
    this.$emit("on-roll", _canPush && this.canRoll)
  }

  handleConfigUpdate(newConf: ICombatDiceConfig) {
    const config = { ...newConf, itemId: newConf.item?.id }
    this.conf = config
  }

  handleDiceUpdate(newDice: IDiceConfig) {
    this.conf = { ...this.conf, diceConf: newDice }
  }

  handleEffectsApplied() {
    this.canPush = false
    this.canRoll = false
    this.$emit("on-effects-applied")
  }

  created() {
    this.updateCanPush()
  }
}
export default DiceCombatBody

function defaultDice(): IDiceConfig {
  return {
    white: null,
    red: null,
    black: null,
    green: null,
    blue: null,
    orange: null,
  }
}
