
import { Component, Prop, Watch, Vue } from "vue-property-decorator"

import { IDiceConfigLogEntry } from "@/dice/diceTypes"
import { DiceImg } from "src/components/diceRoller/DiceImg.vue"

@Component({
  components: {
    DiceImg,
  },
})
export class DiceModifier extends Vue {
  @Prop({ required: true }) entry!: IDiceConfigLogEntry
  dice = this.entry.dice
  name = this.entry.name
  rank = this.entry.rank

  //   modifier = this.entry.modifier
  get modifier() {
    // return this.entry.modifier
    if (this.entry.modifier < 0) {
      return this.entry.modifier
    }
    return `+${this.entry.modifier}`
  }

  get id() {
    if (this.entry.name) {
      return ""
    }
    return this.$t(this.entry.id)
  }

  get suffix() {
    const suffix = []
    if (this.entry.name) {
      suffix.push(this.entry.name)
    }
    if (this.entry.rank) {
      suffix.push(this.entry.rank)
    }
    return suffix.join(" ")
  }

  get text() {
    if (!this.id) {
      return this.suffix
    }
    return `${this.id} ${this.suffix}`
  }
}

export default DiceModifier
