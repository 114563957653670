import { TItem, WEAPON_CATEGORY } from "src/data/items/itemTypes"
import {
  ACTION_ALL,
  ACTION_FAST,
  ACTION_SLOW,
} from "src/data/combat/typesCombat"
import { CharData } from "src/data/character/characterData"
import {
  isRangedWeapon,
  isSlashing,
  isStabbing,
  requiresAmmo,
} from "src/data/items/logic/weaponLogic"

function _combatItems(c: CharData): TItem[] {
  return c.gear.inventory.filter((i) => i.equipped || i.type === "other")
}

export function getCombatItems(c: CharData, actionId: ACTION_ALL): TItem[] {
  const items = _combatItems(c)
  switch (actionId) {
    case ACTION_SLOW.slash:
      return items.filter(isSlashing)

    case ACTION_SLOW.stab:
      return items.filter(isStabbing)

    case ACTION_SLOW.charge:
      return items.filter((i) => !!(isStabbing(i) || isSlashing(i)))

    case ACTION_FAST.fast_shot:
      return items.filter(
        (i) =>
          i.type === "weapon" &&
          isRangedWeapon(i) &&
          i.bonus &&
          (requiresAmmo(i) ? !!c.gear.consumables.arrows : true)
      )
    case ACTION_SLOW.shoot:
      return items.filter(
        (i) =>
          i.type === "weapon" &&
          isRangedWeapon(i) &&
          i.bonus &&
          (requiresAmmo(i) ? !!c.gear.consumables.arrows : true)
      )
    case ACTION_FAST.parry:
      return items.filter(
        (i) =>
          i.type === "shield" || (i.type === "weapon" && i.features.parrying)
      )
    case ACTION_FAST.shield_bash:
      return items.filter((i) => i.type === "shield")

    case ACTION_FAST.disarm:
      return items.filter((i) => !!(isStabbing(i) || isSlashing(i)))

    case ACTION_FAST.shove:
      return items.filter(
        (i) =>
          !!(i.type === "shield" || (i.type === "weapon" && i.features.hook))
      )

    case ACTION_FAST.use_item:
      return items

    default:
      return []
  }
}
