
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import {
  actionsSlow,
  actionsFast,
  actionsAll,
  TARGET,
} from "@/data/combat/combatActions"
import ExpandableSection from "@/components/ExpandableSection.vue"
import { FLSelect, Option, Optgroup } from "@/components/base/FLSelect.vue"
import { FLNumberInput } from "@/components/base/FLNumberInput.vue"
import { IconButton } from "@/components/base/IconButton.vue"
import { CharData } from "@/data/character/characterData"
import { ACTION_ALL } from "@/data/combat/typesCombat"
import { TSkillId } from "@/types"
import { SKILLS } from "@/skills"
import { capitalize } from "@/util/util"
import { Item } from "src/data/items/itemTypes"
import { IDiceConfig, IDiceConfigLogEntry } from "src/dice/diceTypes"
import {
  convertLogToConfig,
  diceLogAttributes,
  diceLogBonus,
  diceLogCombat,
  diceLogItem,
  diceLogSkill,
} from "src/dice/diceConfigurator"

type TCombatOption = Option & { id: ACTION_ALL }

const skills: Option[] = Object.values(SKILLS).map(({ id }) => ({ id }))

@Component({
  components: {
    ExpandableSection,
    FLSelect,
    FLNumberInput,
    IconButton,
  },
})
export class CombatConfig extends Vue {
  @Prop({ default: null }) charData!: CharData
  @Prop({ default: "" }) actionId!: ACTION_ALL | ""
  @Prop({ default: "" }) skillId!: TSkillId | ""
  @Prop({ default: "" }) itemId!: string
  @Prop({ default: false }) isMonster!: boolean

  // FIXME: Remove local variables, handle state with props and emit
  // actionIdLocal = this.actionId
  // skillIdLocal = this.skillId
  // mItemId = this.itemId
  bonus = 0
  target: TARGET = this.isMonster ? TARGET.monster : TARGET.humanoid

  fastOptions: TCombatOption[] = actionsFast
    .filter((a) => a.skill)
    .map((a) => ({ id: a.id }))

  fastGroup: Optgroup = {
    label: "combat-action-fast",
    options: this.fastOptions,
  }

  slowOptions: TCombatOption[] = actionsSlow
    .filter((a) => !!a.skill)
    .map((a) => ({ id: a.id }))

  slowGroup: Optgroup = {
    label: "combat-action-slow",
    options: this.slowOptions,
  }

  get itemSelected() {
    return this.charData?.gear.inventory.find((i) => i.id === this.itemId)
  }

  get none() {
    return capitalize(String(this.$t("none")))
  }

  get actions(): { options: Option[]; groups: Optgroup[] } {
    return {
      options: [{ id: "", name: this.none }],
      groups: [this.fastGroup, this.slowGroup],
    }
  }

  get skills(): Option[] {
    return [{ id: "", name: this.none }, ...skills]
  }

  get targetOptions(): Option[] {
    return [
      { id: "", name: this.none },
      { id: TARGET.humanoid },
      { id: TARGET.monster },
    ]
  }

  get items(): Option[] {
    // TODO: Filter on item type weapon
    return [
      { id: "", name: "-" },
      ...this.charData?.gear.inventory.filter((i) => {
        return i.type === "other" || i.equipped
      }),
    ]
  }

  get backpackItems(): Optgroup[] {
    const options: Option[] = this.charData.gear.inventory.filter(
      (i) => !i.equipped
    )
    return [
      {
        label: String(this.$t("backpack")),
        options,
      },
    ]
  }

  handleBonus(newBonus: number | string) {
    const bonus = Number(newBonus)
    this.bonus = bonus
    this.emit({ bonus })
  }

  handleTarget(newTarget: TARGET) {
    this.emit({ monster: newTarget === TARGET.monster })
  }

  handleActionId(actionId: ACTION_ALL) {
    const newSkillId = actionsAll.find((a) => a.id === actionId)?.skill
    this.emit({ actionId, skillId: newSkillId ?? undefined })
  }

  handleSkillId(newSkillId: TSkillId) {
    this.emit({ skillId: newSkillId })
  }

  handleItem(newItemId: string) {
    this.emit({ itemId: newItemId })
  }

  get baseConfig() {
    const config: IEmitCombatConfig = {
      monster: this.isMonster,
      actionId: this.actionId,
      skillId: this.skillId,
      item: undefined,
      itemId: this.itemId,
      bonus: 0,
      modifiers: [],
      diceConf: {},
    }
    return config
  }

  emit(partialConf: Partial<IEmitCombatConfig>) {
    const config: IEmitCombatConfig = {
      ...this.baseConfig,
      ...partialConf,
    }

    const update: IEmitCombatConfig = getUpdate(config, this.charData)
    this.$emit("update", update)
  }

  created() {
    const update = getUpdate(this.baseConfig, this.charData)
    this.emit(update)
  }
}

export function getUpdate(
  config: IEmitCombatConfig,
  charData: CharData
): IEmitCombatConfig {
  const item = charData.gear.inventory.find((i) => i.id === config.itemId)

  const modifiers: IDiceConfigLogEntry[] = [
    diceLogAttributes(charData, config.skillId),
    diceLogSkill(charData, config.skillId),
    diceLogItem(item),
    diceLogCombat(charData, config.actionId ?? "", item, config.monster),
    diceLogBonus(config.bonus ?? 0),
  ].flat()
  const diceConf = convertLogToConfig(modifiers)

  const update: IEmitCombatConfig = {
    ...config,
    modifiers,
    diceConf,
    item,
  }
  return update
}

export interface IEmitCombatConfig {
  actionId: ACTION_ALL
  item: Item | undefined
  itemId: string | undefined
  monster: boolean
  skillId: TSkillId | undefined | ""
  bonus: number

  modifiers: IDiceConfigLogEntry[]
  diceConf: IDiceConfig
}

export default CombatConfig
