
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import { RollArmorButton } from "./RollArmorButton.vue"
import { FLNumberInput } from "@/components/base/FLNumberInput.vue"
import FLButton from "@/components/base/FLButton.vue"
import { CharData } from "@/data/character/characterData"
import { CombatActionBox } from "./CombatActionBox.vue"
import { FLCheckbox } from "src/components/base/FLCheckbox.vue"

@Component({
  components: {
    CombatActionBox,
    FLButton,
    FLCheckbox,
    FLNumberInput,
    RollArmorButton,
  },
})
export class Combat extends Vue {
  @Prop({ required: true }) charData!: CharData

  round = 0

  modalOpen = false
  monsterOpponent = false

  initiative: null | number = null
  fastActionPerformed = false
  slowActionPerformed = false

  created() {
    const storedInitiative = Number(sessionStorage.getItem("combat-initiative"))
    if (storedInitiative) {
      this.initiative = storedInitiative
    }
  }

  setInitiative(value: number) {
    this.initiative = Number(value)
    sessionStorage.setItem("combat-initiative", String(value))
  }

  nextRound() {
    this.fastActionPerformed = false
    this.slowActionPerformed = false
    this.round++
  }
}

export default Combat
