
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import SvgIcon from "@/components/SvgIcon.vue"
import {
  ExpandableSection,
  EXPANDER_SLOT,
} from "@/components/ExpandableSection.vue"
import DiceResult from "@/components/dice/DiceResult.vue"
import { DICE_TYPE, IDiceResult } from "src/dice/diceTypes"

@Component({
  components: {
    DiceResult,
    ExpandableSection,
    SvgIcon,
  },
})
export class DiceRollResultSection extends Vue {
  @Prop({ required: true }) rollResult!: IDiceResult[]
  @Prop({ required: true }) canPush!: boolean

  EXPANDER_SLOT = EXPANDER_SLOT
  DICE_TYPE = DICE_TYPE

  get totalSuccess() {
    return this.rollResult[0].successes
  }

  get totalWhiteSkulls() {
    return this.rollResult[0].failWhite
  }

  get totalBlackSkulls() {
    return this.rollResult[0].failBlack
  }

  get pushed() {
    return this.rollResult.length >= 2
  }

  get rolled() {
    return this.rollResult.length >= 1
  }

  get rollResultLog() {
    const rounds = this.rollResult.map((r) => r.rollLog)
    return rounds
  }

  get negativeBonus() {
    return this.rollResult[0].negativeBonus
  }

  pushRoll() {
    // TODO: Add button inside component to push the roll
  }
}

export default DiceRollResultSection
