
import DiceActions from "@/components/dice/DiceActions.vue"

import { CharData } from "@/data/character/characterData"
import { Component, Prop, Vue } from "vue-property-decorator"
import { FLNumberInput } from "@/components/base/FLNumberInput.vue"
import { ACTION_ALL, ACTION_FAST } from "@/data/combat/typesCombat"
import { rollDiceType } from "src/dice/diceRoller"

@Component({
  components: {
    DiceActions,
    FLNumberInput,
  },
})
export class DiceCombatFooter extends Vue {
  @Prop({ default: null }) charData!: CharData
  @Prop({ default: false }) canPush!: boolean
  @Prop({ default: true }) canRoll!: boolean
  @Prop({ default: "" }) actionId!: ACTION_ALL | ""

  arrowRoll: number = 6

  get showWillpower() {
    return this.charData && this.charData.willpower !== null
  }

  close() {
    this.$emit("close")
  }

  onWillpower(value: number) {
    this.charData.willpower = value
  }
}
export default DiceCombatFooter
