
import { Component, Prop, Vue } from "vue-property-decorator"

import { CharData } from "@/data/character/characterData"
import { ACTION_ALL, ICombatAction } from "@/data/combat/typesCombat"
import { CombatActionRow } from "./CombatActionRow.vue"
import { TSkillId } from "@/types"
import { DiceCombatModal } from "src/components/dice/combat/DiceCombatModal.vue"

@Component({
  components: {
    CombatActionRow,
    DiceCombatModal,
  },
})
export class CombatActionBox extends Vue {
  @Prop({ required: true }) charData!: CharData
  @Prop({ required: true }) round!: number
  @Prop({ required: true }) fastActionDone!: boolean
  @Prop({ required: true }) slowActionDone!: boolean
  @Prop({ required: true }) monsterOpponent!: boolean

  modalOpen = false
  modalItemId = ""
  modalSkillId: TSkillId | "" = ""
  modalActionId: ACTION_ALL | "" = ""

  handleRoll(data: { action: ICombatAction; itemId: string }) {
    this.modalItemId = data.itemId
    this.modalSkillId = data.action.skill || ""
    this.modalActionId = data.action.id
    this.modalOpen = true
  }
}
export default CombatActionBox
