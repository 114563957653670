import { render, staticRenderFns } from "./DiceRollEffects.vue?vue&type=template&id=7b47ab95&scoped=true&"
import script from "./DiceRollEffects.vue?vue&type=script&lang=ts&"
export * from "./DiceRollEffects.vue?vue&type=script&lang=ts&"
import style0 from "./DiceRollEffects.vue?vue&type=style&index=0&id=7b47ab95&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b47ab95",
  null
  
)

export default component.exports