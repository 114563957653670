import { render, staticRenderFns } from "./ModifiersSection.vue?vue&type=template&id=71f00b4f&scoped=true&"
import script from "./ModifiersSection.vue?vue&type=script&lang=ts&"
export * from "./ModifiersSection.vue?vue&type=script&lang=ts&"
import style0 from "./ModifiersSection.vue?vue&type=style&index=0&id=71f00b4f&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71f00b4f",
  null
  
)

export default component.exports