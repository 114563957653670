
// TODO: Split this up into smaller components
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import {
  ExpandableSection,
  EXPANDER_SLOT,
} from "@/components/ExpandableSection.vue"
import { IDiceConfig } from "@/dice/diceTypes"

import { DiceProbability } from "@/components/dice/DiceProbability.vue"
import { capitalize } from "@/util/util"

@Component({
  components: {
    DiceProbability,
    ExpandableSection,
  },
})
export class DiceProbabilitySection extends Vue {
  @Prop({ default: null }) diceConf!: IDiceConfig

  EXPANDER_SLOT = EXPANDER_SLOT

  get probabilityStr() {
    return capitalize(this.$t("probability") as string)
  }
}

export default DiceProbabilitySection
