
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import {
  ExpandableSection,
  EXPANDER_SLOT,
} from "@/components/ExpandableSection.vue"
import { DiceModifier } from "src/components/diceRoller/DiceModifier.vue"

import { TDiceConfigLog } from "@/dice/diceTypes"

@Component({
  components: {
    ExpandableSection,
    DiceModifier,
  },
})
export class ModifiersSection extends Vue {
  @Prop({ required: true }) diceLog!: TDiceConfigLog | null

  SLOT = EXPANDER_SLOT

  get diceLogFiltered() {
    return (
      this.diceLog?.filter((l) => {
        return !!l.modifier
      }) || []
    )
  }
}

export default ModifiersSection
